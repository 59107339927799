.Select-menu-outer {
    height: 480px !important;
    overflow-y: visible !important;
    max-height: 480px !important;
}

.Select-menu {
    max-height: 480px !important;
}

.stro-button.stro-right-button span {
    margin-top: 30px !important;
}