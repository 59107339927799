.fade:not(.show) {
  opacity: 1;
}

.popover-title {
  background: #eee;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
}
